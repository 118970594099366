import React from 'react'
import styled from 'styled-components'
import { DefaultLayout } from '../components/Layout'
import { Ellipsis } from '../components/Typography'

const List = styled.ul`
  list-style: circle;
  li {
    margin-left: 3em;
  }
`

const AboutPage = () => (
  <DefaultLayout title="About Milaco">
    <h1>
      About Milaco
      <Ellipsis />
    </h1>
    <p>
      Milaco Engineering Ltd are experts in assessment, inspection and design for companies looking to comply with
      mandatory legal requirements for potentially explosive atmospheres.
    </p>
    <p>
      {' '}
      At our core, we focus on offering individually tailored support packages for organisations who need to comply with
      Dangerous Substances and Explosive Atmospheres Regulations 2002 (DSEAR) or any equivalent international
      regulations that our customers need to adhere too.{' '}
    </p>
    <p> our services include:</p>
    <List>
      <li>Dsear Risk Assessment </li>
      <li>Area Classifications </li>
      <li>Atex Equipment Inspections</li>
      <li>Hazardous Area Electrical Testing</li>
      <li>Hazardous Area Drawings</li>
      <li>Intrinsically Safe Documentation</li>
    </List>
    <p>
      At Milaco, we are fully aware of the challenges, added stress and difficulties which our clients come across when
      trying to comply with DSEAR regulations alongside their day to day operations.
    </p>
    <p>
      As a company, we can offer supporting and fully managed services of your hazardous areas. Our customisable service
      can be tailored to your organisations' individual needs allowing you to become fully compliant in a
      cost-effective, timely manner. All services are completed by our Engineers who are experts with vast skills,
      experience, and knowledge across multiple sectors. Our Engineers are offered continuous training to ensure they
      remain up to date with current and previous standards.
    </p>
    <p>
      We aim to build strong relationships with our customers through cost-effective and adaptable mobilisation of your
      compliance needs. Our experts can offer accurate and reliable results, providing our customers with tangible
      actions and services to improve their sites' productivity, safety and efficiency.
    </p>
  </DefaultLayout>
)

export default AboutPage
